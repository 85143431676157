import React from "react";
import Layout from "../../components/layout";
import ProjectArticle from "../../components/projectArticle";
import ProjectImage from "../../components/projectImage";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const DigitalSafety = (props) => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: {eq: "images/digital-safety/allstate-hero.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            pngOptions: {compressionSpeed: 1, quality: 100}
          )
        }
      },
      matrix: file(relativePath: {eq: "images/digital-safety/password-matrix.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            pngOptions: {compressionSpeed: 1, quality: 100}
          )
        }
      },
      stim: file(relativePath: {eq: "images/digital-safety/research-stim.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            pngOptions: {compressionSpeed: 1, quality: 100}
          )
        }
      },
      list: file(relativePath: {eq: "images/digital-safety/list-updates.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            pngOptions: {compressionSpeed: 1, quality: 100}
          )
        }
      },
      generate: file(relativePath: {eq: "images/digital-safety/generate-password.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            pngOptions: {compressionSpeed: 1, quality: 100}
          )
        }
      },
      viewing: file(relativePath: {eq: "images/digital-safety/viewing-passwords.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            pngOptions: {compressionSpeed: 1, quality: 100}
          )
        }
      },
    }
  `)

  return (
    <Layout theme='light'>
      <div className='flex flex-col md:grid grid-cols-desktop project md:max-h-hero -mt-6 md:mb-12 h-auto'>
      <div className='col-start-2 col-span-5'>
          <h1 className='text-6xl md:text-8xl lg:text-9xl my-8'>Allstate Digital Safety</h1>
          <p className='font-serif mb-2 text-black'><span className='font-bold'>ROLE:</span> Product Designer</p>
          <p className='font-serif mb-2 text-black'><span className='font-bold'>INDUSTRY:</span> Digital Security</p>
          <p className='font-serif mb-2 text-black'><span className='font-bold'>DATE:</span> 2018-2019</p>
      </div>
        <GatsbyImage
          alt={'An example screen from the project'}
          image={getImage(data.hero)}
          objectPosition='top'
          className={'md:-mr-36 2xl:mr-0 shadow-lg col-start-8 col-span-8 object-cover object-top h-80 md:h-full md:max-h-hero rounded-lg order-first md:order-last'}
          imgClassName={`rounded-lg`}
        />
      </div>
      <ProjectArticle>
        <section>
          <h2>The Project</h2>
          <p>Allstate Digital Safety was Allstate's first foray into identity protection. The iOS app helped users visualize, monitor, and take control of their "digital footprint."</p>
          <p>Digital Safety operated with a freemium model. One of the first in-app premium features was a password manager I designed from the ground up.</p>
        </section>
        <section>
          <h2>The Convenience Barrier</h2>
          <p>Whole companies are built around password management. Our team had to quickly orient toward an approach that would create user value even though we would launch with a limited, mobile-only feature set—critically, no browser extensions that automatically capture and autofill passwords.</p>
          <p>Our initial user research showed us that many of our target users used browser-based password managers that prioritized convenience over security. Our feature set's lack of convenience was a barrier we would have to overcome to be successful.</p>
          <p>We decided to focus on providing security for customers' most important but less frequently accessed accounts. Passwords would still need to be easy to find in the app, but the extra friction our limited feature set caused could add a sense of security over browser-based password managers (and sticky notes).</p>
        </section>
        <ProjectImage
          imageObject={data.matrix}
          altText='An example of the before and after of the designs'
          imageClass={'shadow-md'}
        />
        <section>
          <h2>Finding a Home</h2>
          <p>Initially, the business wanted the password manager to live in a premium section of the app, only accessible to subscribers. In user research, we saw that this approach confused participants because it required two lists of accounts. The app used one account list on the home screen to show the users their entire digital footprint. A standalone password manager required another account list in the premium section that only included accounts with passwords.</p>
        </section>
        <ProjectImage
          imageObject={data.stim}
          altText='An example of the before and after of the designs'
          imageClass={'shadow-md'}
          caption='The original concept required two different (but similar) account lists.'
        />
        <section>
          <p>Using those findings, I designed ways to integrate the password manager with the core feature set. This approach made it easier for users to find their passwords and gave the business new opportunities to upsell premium features.</p>
        </section>
        <section>
          <h2>Integrating a Premium Feature</h2>
          <p>Integrating the password manager into the core experience required two significant changes to the primary account list.</p>
          <p>First, we needed to make it possible for users to find passwords as quickly as possible. The team had initially designed the list for people to browse the accounts in their digital footprint, and it would take a lot of scrolling before someone could find their Spotify password. So we added a simple search feature that would narrow the list based on account names and URLs.</p>
          <p>Second, the app initially identified accounts by scanning for emails that arrive after someone signs up for a service. Unfortunately, people choose passwords when they first sign up. So we also needed to allow users to add new accounts and passwords to their footprint manually. (We didn't have the luxury of relying on a browser extension to capture new passwords automatically.)</p>
        </section>
        <ProjectImage
          imageObject={data.list}
          altText='An example of the before and after of the designs'
          imageClass={'shadow-md'}
          caption='Basic search and account creation features are accessible via pull-down on the primary account list so that users could quickly find and create passwords.'
        />
        <ProjectImage
          imageObject={data.viewing}
          altText='An example of the before and after of the designs'
          imageClass={'shadow-md'}
        />
        <section>
          <p>These updates made it possible to integrate the password manager in a way that made passwords feel like they were a meaningful part of a user's digital footprint. They also opened up new ways for users to interact with their footprint, moving the product closer to its goal of being an experience built around curation.</p>
          <h2>Maintaining Control</h2>
          <p>We also learned through research that users were uncomfortable ceding total control of password creation to the app. Many were worried about having passwords stored in the app that “only a computer could remember”. And it turns out that diceware phrases are more secure anyway.</p>
        </section>
        <ProjectImage
          imageObject={data.generate}
          altText='An example of the before and after of the designs'
          imageClass={'shadow-md'}
        />
        <section>
          <p><i>The standalone Allstate Digital Safety app has since been rolled into Allstate Identity Protection programs and is available as part of Allstate's iOS and Android apps.</i></p>
        </section>
      </ProjectArticle>
    </Layout>
  );
};

export default DigitalSafety;
